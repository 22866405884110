const colors = [
    ['38, 63, 68', '250, 245, 228'],
    ['35, 34, 63', '226, 197, 183'],
    ['54, 97, 167', '255, 255, 255'],
    ['34, 40, 49', '68, 207, 202'],
    ['29, 77, 79', '229, 223, 223'],
    ['60, 66, 69', '223, 205, 195'],
    ['204, 56, 56', '249, 213, 187'],
];

let [darkColor, lightColor] = colors[Math.floor(Math.random() * colors.length)];

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    [frontColor, backColor] = [lightColor, darkColor]
} else {
    [frontColor, backColor] = [darkColor, lightColor]
}

const rootStyles = document.documentElement.style;
rootStyles.setProperty('--c-primary-front', frontColor);
rootStyles.setProperty('--c-primary-back', backColor);

document.querySelectorAll('[data-color-prop]')
    .forEach((element) => {
        console.log(element, element.dataset.colorProp);
        element.setAttribute(element.dataset.colorProp, `rgb(${lightColor})`)
    });
