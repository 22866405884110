import '@babel/polyfill';

import "sanitize.css";

import './base';
import './config';
import './helpers';
import './screens';

import './services/color-changer';
